import "bootstrap/js/dist/tab"
import "bootstrap/js/dist/modal"
import { selectReset, selectAddOptions, selectSpinnerShow, selectSpinnerHide } from "Utils/Form"
import { getBaseUrl } from "Utils/Uri"
import { triggerEvent } from "Utils/Functions"

document.addEventListener("DOMContentLoaded", async () => {
  const { Client } = await import("Http/Client")
  const request = new Client()
  const carouselOptions = {
    margin: 20,
    autoplayTimeout: 5000,
    rtl:true,
    autoplayHoverPause: true,
    autoplay: true,
    loop: false,
    rewind: true,
    dots: false,
    nav: true,
    lazyLoad: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      576: {
        items: 2,
        nav: true
      },
      768: {
        items: 2,
        nav: true
      },
      992: {
        items: 3,
        nav: true
      },
      1367: {
        items: 4,
        nav: true
      }
    }
  }
  const $latestVideos = $(".latest-videos")
  const $brandsSlider = $(".brands-slider")
  const $promotionalCars = $(".promotional-cars")
  const $newestCars = $(".newest-cars")
  const $announcements = $(".announcements")

  if ($latestVideos.length > 0) {
    $latestVideos.owlCarousel(carouselOptions)
  }
  if ($brandsSlider.length > 0) {
    $brandsSlider.owlCarousel(carouselOptions)
  }
  if ($promotionalCars.length > 0) {
    $promotionalCars.owlCarousel(carouselOptions)
  }
  if ($newestCars.length > 0) {
    $newestCars.owlCarousel(carouselOptions)
  }
  if ($announcements.length > 0) {
    $announcements.owlCarousel(carouselOptions)
  }

  const newestForm = document.querySelector("#newest-search-form")
  const newestBrands = document.querySelector("#newest_brands")
  const newestCars = document.querySelector("#newest_cars")

  if (newestBrands !== null && newestCars !== null) {
    searchFetch(newestBrands, newestCars, "recuperation/Modeles")
  }

  if (newestForm !== null) {
    newestForm.addEventListener("submit", async function (event) {
      event.preventDefault()
      const brand = newestBrands.value
      const car = newestCars.value
      const category = this.querySelector(`input[name="category"]:checked`)

      if (category === null) {
        if (brand !== "") {
          if (car !== "") {
            const data = await request.post(getBaseUrl("recuperation/Modeles/info"), {c: car})
            if (data.error === false) {
              window.location = data.url
            }
          } else {
            const data = await request.post(getBaseUrl("recuperation/Marques/info"), {b: brand})
            if (data.error === false) {
              window.location = data.url
            }
          }
        }
      } else {
        let params = [`category=${category.value}`]

        if (brand !== "") {
          const data = await request.post(getBaseUrl("recuperation/Marques/info"), {b: brand})
          if (data.error === false) {
            params.push(`brand[]=${data.name}`)
          }
        }

        window.location = getBaseUrl("le-neuf") + "?" + params.join("&")
      }
    })
  }

  carBodiesToggler("#newest-car-bodies")
  carBodiesToggler("#announcement-car-bodies")

  const announcementForm = document.querySelector("#announcement-search-form")
  const announcementBrands = document.querySelector("#announcement_brands")
  const announcementCars = document.querySelector("#announcement_cars")

  if (announcementBrands !== null && announcementCars !== null) {
    searchFetch(announcementBrands, announcementCars, "recuperation/ModelesAnnonces")
  }

  if (announcementForm !== null) {
    announcementForm.addEventListener("submit", async function (event) {
      event.preventDefault()
      const brand = announcementBrands.value
      const car = announcementCars.value
      const category = this.querySelector(`input[name="category"]:checked`)
      const searchLink = getBaseUrl("voitures-occasion")
      let params = []

      if (category !== null) {
        params.push(`category=${category.value}`)
      }

      if (brand !== "") {
        if (car !== "") {
          params.push(`q=${car}`)
        }

        const data = await request.post(getBaseUrl("recuperation/Marques/info"), {b: brand})
        if (data.error === false) {
          params.push(`brand[]=${data.name}`)
        }
      }

      window.location = params.length > 0 ? searchLink + "?" + params.join("&") : searchLink
    })
  }

  const multiple_button = document.querySelector(".multiple-comparison-button")

  if (multiple_button !== null) {
    const { Comparison } = await import("Components/Comparison")
    new Comparison(".multiple-comparison-button", {
      action: "add",
      mode: "multiple",
      modal_dialog: " modal-sm modal-right",
    })
  }

  function searchFetch (brands, cars, segment) {
    cars.disabled = true

    brands.addEventListener("change", async function () {
      selectReset(cars, true)

      if (this.value !== '') {
        selectSpinnerShow(cars)
        const data = await request.post(getBaseUrl(segment), {b: this.value})
        selectAddOptions(cars, data)
        cars.disabled = false
        selectSpinnerHide(cars)
      }
    })

    if (brands.value !== '' && cars.value === '') {
      triggerEvent(brands, "change")
    }
  }

  function carBodiesToggler (selector) {
    const radios = document.querySelectorAll(`${selector} li input[type=radio]`)

    if (radios.length > 0) {
      for (const radio of radios) {
        radio.addEventListener("click", function () {
          Array.from(radios).forEach((r) => {
            r.checked = false
            r.closest("li").classList.remove("checked")
          })

          this.closest("li").classList.add("checked")
          this.checked = true
        })
      }
    }
  }
})